import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './marker.css'
import TreeIcon from './tree.png'
import { Card } from './children'

const Marker = (props) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className="marker"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && <Card treeCount={props.treeCount} image={props.image} description={props.description} />}
      <img src={TreeIcon} className="tree-icon" alt="Tree icon" />
    </div>
  )
} 

Marker.propTypes = {
  treeCount: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default Marker
