import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: '2',
    margin: theme.spacing(1)
  },

  header: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1)
  }
}))

const fakeData = [
  { name: 'Wolverhampton', count: 40 },
  { name: 'Coventry', count: 2 },
  { name: 'Walsall', count: 14 }
]

const DetailsOverlay = () => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography>
          Total Trees Planted: 56
        </Typography>

        <Typography
          className={classes.header}
        >
          Areas:
        </Typography>
        
        {fakeData.map((data, index) => (
          <Typography
            key={data.key}
          >
            {`${data.name}: ${data.count}`}
          </Typography>
        ))}
      </CardContent>
    </Card>
  )
}

export default DetailsOverlay
