import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: '50px'
  },

  title: {
    textAlign: 'center',
    marginBottom: '10px'
  },

  image: {
    width: '200px',
  }
})

const CardPopOut = (props) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <img className={classes.image} src={props.image} alt="logo" />
        <Typography variant="h6" className={classes.title}>
          {props.treeCount} Trees planted
        </Typography>
        <Typography variant="body1">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  )
}

CardPopOut.propTypes = {
  treeCount: PropTypes.number.isRequired,
  image: PropTypes.string,
  description: PropTypes.string
}

export default CardPopOut
