import React from 'react'
import Map from '../components/map'
import Header from '../components/header'
import data from './data'

const App = () => (
  <React.Fragment>
    <Header />
    <Map
      markers={data.markers}
      center={{ lat: 52.48721, lng: -1.89976 }}
    />
  </React.Fragment>
)

export default App
