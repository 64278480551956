import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EducationHostLogo from './images/education-host.jpg'
import WmcaLogo from './images/wmca.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  
  logo: {
    width: '208px'
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const Header = ({ text }) => {
  const classes = useStyles()

  return (
    <header className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={EducationHostLogo} alt="Education Host Logo" />
      </div>
      
      <Typography variant="h4" as="h1">
        {text}
      </Typography>

      <div className={classes.logoContainer}>
        <img className={classes.logo} src={WmcaLogo} alt="WMCA Logo" />
      </div>      
    </header>
  )
}

Header.propTypes = {
  text: PropTypes.string.isRequired
}

Header.defaultProps = {
  text: 'Virtual Forest - Education Host Demonstration'
}

export default Header

