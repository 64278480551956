import React from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { DetailsOverlay, Marker } from './children'

const Map = (props) => (
  <div style={{ height: 'calc(100vh - 140px)', width: '100%', position: 'relative' }}>
    <DetailsOverlay />
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
      defaultCenter={props.center}
      defaultZoom={props.zoom}
      scrollwheel={false}
    >      
      {props.markers.map((marker, index) => (
        <Marker
          key={index}
          lat={marker.lat}
          lng={marker.lng}
          treeCount={marker.treeCount}
          image={marker.image}
          description={marker.description}
        />
      ))}
    </GoogleMapReact>
  </div>
)

Map.defaultProps = {
  markers: [],
  center: {
    lat: 59.95,
    lng: 30.33
  },
  zoom: 11}

Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.object),
  center: PropTypes.object,
  zoom: PropTypes.number
}

export default Map
