import logo from './logo.jpg'

const data = {
  markers: [
    { lat: 52.58721, lng: -1.89976, treeCount: 14, image: 'https://ichef.bbci.co.uk/wwfeatures/live/976_549/images/live/p0/7n/19/p07n19vr.jpg', description: 'This is the description for the card' },
    { lat: 52.44721, lng: -1.59953, treeCount: 2, image: logo, description: 'This is the description for the card' },
    { lat: 52.5870, lng: -2.1288, treeCount: 40, image: 'https://www.wmca.org.uk/media/2439/wmca-default.png', description: '40 Trees have been planted on 21/4/2020 to celebrate the launch of the Virtual Forest.' },
  ]
}

export default data
